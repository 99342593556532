import { useState, useEffect } from 'react';
import { ToggleLeft, ToggleRight, Settings } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ModeSelector = () => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchStatus = async () => {
    try {
      const response = await fetch('https://empire-back.selaris.app/api/gamemode/status');
      if (!response.ok) throw new Error('Failed to fetch status');
      const data = await response.json();
      setIsActive(data.isActive);
    } catch (err) {
      console.error('Error fetching status:', err);
      setError('Failed to fetch mode status');
    }
  };

  const toggleMode = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://empire-back.selaris.app/api/gamemode/status', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isActive: !isActive }),
      });
      
      if (!response.ok) throw new Error('Failed to update status');
      setIsActive(!isActive);
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update mode');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
    const interval = setInterval(fetchStatus, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 mb-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-violet-500/10 rounded-xl">
            <Settings className="text-violet-400" size={24} />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-white">Mode Selector</h2>
            <p className="text-gray-400 text-sm">
              {isActive ? 'Mode is currently active' : 'Mode is currently inactive'}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4">
          {error && (
            <span className="text-red-400 text-sm">
              {error}
            </span>
          )}
          
          <motion.button
            onClick={toggleMode}
            disabled={isLoading}
            className={`relative p-2 rounded-lg transition-colors ${
              isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-violet-500/10'
            }`}
            whileHover={{ scale: isLoading ? 1 : 1.05 }}
            whileTap={{ scale: isLoading ? 1 : 0.95 }}
          >
            <AnimatePresence mode="wait">
              {isActive ? (
                <motion.div
                  key="active"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                >
                  <ToggleRight className="w-12 h-12 text-violet-400" />
                </motion.div>
              ) : (
                <motion.div
                  key="inactive"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                >
                  <ToggleLeft className="w-12 h-12 text-gray-400" />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default ModeSelector;