import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Ticket, Users, ShipWheel, Activity, Settings, TrendingUp, AlertTriangle } from 'lucide-react';
import { API_URL } from './api_config';

interface DashboardCardProps {
  title: string;
  value: string | number | null;
  icon: React.ReactNode;
  color: string;
  isLoading?: boolean;
}

interface ApiResponse {
  status: string;
  data: {
    total?: number;
    totalValue?: number;
    drawCount?: number;
  };
}

const WeeklyActivityChart: React.FC = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const days: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  
  const generateActivityData = (): number[] => {
    const baseData = [45, 60, 75, 65, 80, 70, 90];
    return baseData.map(base => base + Math.random() * 20);
  };

  const activityData = generateActivityData();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
    >
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-violet-500/10 rounded-xl">
          <TrendingUp className="w-6 h-6 text-violet-400" />
        </div>
        <div>
          <h2 className="text-xl font-bold text-white">Weekly Activity</h2>
          <p className="text-gray-400 text-sm">User engagement over time</p>
        </div>
      </div>

      <div ref={chartRef} className="h-64 flex items-end justify-between relative">
        <svg 
          className="absolute top-0 left-0 w-full h-full pointer-events-none z-0" 
          viewBox="0 0 700 300"
        >
          <path 
            d={`M 0 ${300 - activityData[0] * 2} Q 100 ${300 - (activityData[0] + activityData[1]) * 1.5}, 200 ${300 - activityData[2] * 2} T 400 ${300 - activityData[4] * 2} T 600 ${300 - activityData[6] * 2}`}
            fill="none"
            stroke="rgba(139, 92, 246, 0.2)"
            strokeWidth="3"
          />
        </svg>

        {days.map((day, index) => (
          <div key={day} className="flex flex-col items-center z-10 relative">
            <div 
              className={`w-12 rounded-t-md transition-all duration-500 ${
                day === 'Sun' 
                  ? 'bg-gradient-to-t from-violet-600 to-violet-400 scale-110 shadow-lg shadow-violet-500/20' 
                  : 'bg-gradient-to-t from-gray-700 to-gray-600'
              }`}
              style={{ height: `${activityData[index]}%` }}
            >
              <div className="absolute w-3 h-3 rounded-full -top-2 left-1/2 -translate-x-1/2 bg-violet-400 shadow-lg shadow-violet-500/30" />
            </div>
            <span className={`text-sm mt-2 ${
              day === 'Sun' ? 'text-violet-400 font-semibold' : 'text-gray-400'
            }`}>{day}</span>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

const DashboardCard: React.FC<DashboardCardProps> = ({ title, value, icon, color, isLoading }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
  >
    <div className={`p-6 border-l-4 ${color}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className={`p-3 rounded-xl ${color.replace('border-', 'bg-')}/10`}>
            {icon}
          </div>
          <div className="ml-4">
            <h3 className="text-gray-400 text-sm">{title}</h3>
            {isLoading ? (
              <div className="h-6 w-24 bg-gray-700 rounded animate-pulse mt-1" />
            ) : (
              <p className="text-2xl font-bold text-white">{value}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);

const CustomAlert = () => (
  <motion.div 
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    className="mb-6 p-4 rounded-lg border border-yellow-500/50 bg-yellow-500/10 flex items-center gap-3"
  >
    <AlertTriangle className="h-5 w-5 text-yellow-500" />
    <p className="text-yellow-200">
      Statistics are currently in beta. A comprehensive update will be pushed soon to improve accuracy and add new metrics.
    </p>
  </motion.div>
);

const MainContent: React.FC = () => {
  const [promoCodeCount, setPromoCodeCount] = useState<number | null>(null);
  const [promoCodeValue, setPromoCodeValue] = useState<number | null>(null);
  const [drawValue, setDrawValue] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [promoResponse, valueResponse, drawResponse] = await Promise.all([
          fetch(API_URL + '/promo-codes/count'),
          fetch(API_URL + '/promo-codes/count-value'),
          fetch(API_URL + '/game/drawCount')
        ]);

        const promoData: ApiResponse = await promoResponse.json();
        const valueData: ApiResponse = await valueResponse.json();
        const drawData: ApiResponse = await drawResponse.json();

        setPromoCodeCount(promoData.data.total || 0);
        setPromoCodeValue(valueData.data.totalValue || 0);
        setDrawValue(drawData.data.drawCount || 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <div className="max-w-7xl mx-auto">
      <CustomAlert />
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <Settings className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Dashboard Overview
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Monitor your platform's performance
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <DashboardCard 
            title="Total Promo Codes" 
            value={promoCodeCount ? `${promoCodeCount.toLocaleString()}` : null}
            icon={<Ticket className="text-violet-400" size={24} />} 
            color="border-violet-500"
            isLoading={isLoading}
          />
          <DashboardCard 
            title="Total € Dropped" 
            value={promoCodeValue ? `${promoCodeValue.toLocaleString()}€` : null}
            icon={<Users className="text-emerald-400" size={24} />} 
            color="border-emerald-500"
            isLoading={isLoading}
          />
          <DashboardCard 
            title="Total Draw" 
            value={drawValue}
            icon={<ShipWheel className="text-blue-400" size={24} />} 
            color="border-blue-500"
            isLoading={isLoading}
          />
          <DashboardCard 
            title="UP Stats" 
            value="46%" 
            icon={<Activity className="text-red-400" size={24} />} 
            color="border-red-500"
            isLoading={isLoading}
          />
        </div>

        <WeeklyActivityChart />
      </div>
    </div>
  );
};

export default MainContent;